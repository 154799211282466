<template>
  <div>
    <FamilyImageBanner />

    <section class="mt-3">
      <b-container>
        <b-row class="d-flex justify-content-between align-items-center">
          <b-col>
            <h1>Relaties</h1>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <b-button @click="newUser" variant="primary" v-b-modal.add-relation>
              <b-icon icon="person-plus-fill" variant="light" class="mr-1"></b-icon>
              Voeg familielid toe
            </b-button>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col v-if="!users || users.length == 0" cols="12" sm="6" md="4">
            <b-card body-class="d-flex flex-column align-items-center">
              <feather-icon icon="UsersIcon" size="50"></feather-icon>
              <p class="mt-2">Voeg een familielid toe</p>
              <b-button @click="newUser" variant="primary" v-b-modal.add-relation>
                <b-icon icon="person-plus-fill" variant="light" class="mr-1"></b-icon>
                Voeg familielid toe
              </b-button>
            </b-card>
          </b-col>
          <b-col cols="12" sm="6" md="4" v-for="(user, index) in users" :key="index">
            <b-card v-bind:class="{ 'blocked-relation': user.blocked }">
              <div v-if="user.blocked" class="text-danger text-center font-weight-bold">Geblokkeerd</div>
              <div class="d-flex align-items-center">
                <b-avatar size="lg" :src="user.image_base64" :text="!user.image ? user.initials : null"></b-avatar>
                <div class="ml-1">
                  <div><strong>{{ user.firstname }} {{ user.infix }} {{ user.lastname }}</strong></div>
                  <div v-if="(user.relation_type.code == 'child') || (user.relation_type == 'child')">Familielid</div>
                  <div v-if="(user.relation_type.code == 'partner') || (user.relation_type == 'partner')">Partner</div>
                </div>
              </div>

              <b-row class="mt-2">
                <b-col cols="12" xl="6" class="mb-1">
                  <div><strong>Geboortedatum</strong></div>
                  <div>{{ user.birthdate }}</div>
                </b-col>
                <b-col v-if="user.email && user.email.length > 0" cols="12" xl="6" class="mb-1">
                  <div><strong>Email</strong></div>
                  <div class="text-wrapping">{{ user.email }}</div>
                </b-col>
                <b-col v-if="user.partner && user.partner.user_b" cols="12" md="6" class="mb-1">
                  <div><strong>Partner</strong></div>
                  <div>{{ user.partner.user_b.firstname }} {{ user.partner.user_b.lastname }}</div>
                </b-col>

                <b-col cols="12" xl="6" class="mb-1">
                  <div><strong>Login mogelijkheid?</strong></div>
                  <div>{{ user.invited || user.login ? "Ja" : "Nee" }}</div>
                </b-col>
              </b-row>

              <div class="text-center">
                <b-button pill variant="primary" v-b-modal="`edit-relation-` + user.hashid">
                  Wijzig
                </b-button>
                <b-modal dialog-class="relation-modal" :id="`edit-relation-` + user.hashid" no-enforce-focus no-close-on-backdrop centered hide-footer size="lg">
                  <AddRelationsModal @editUser="userEdited" @deleteUser="deleteUser" :edit="user" @reloadUsers="reloadUsers" />
                </b-modal>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <b-modal id="add-relation" dialog-class="relation-modal" no-enforce-focus no-close-on-backdrop centered hide-footer size="lg">
      <AddRelationsModal @addUser="addUser" @deleteUser="deleteUser" :edit="editedUser" @reloadUsers="reloadUsers" />
    </b-modal>
  </div>
</template>

<script>
// import store from '@/store'
import AddRelationsModal from '@/views/family-management/modals/AddRelationsModal.vue'
import FamilyImageBanner from '@/components/family-image-banner.vue'

export default {
  components: {
    AddRelationsModal,
    FamilyImageBanner,
  },
  data() {
    return {
      users: [
        /*{
          image: require('@/assets/images/avatars/10-small.png'),
          firstName: "Eric",
          affix: "",
          lastName: "Verhoeven",
          birthday: "1980-08-09",
          email: "eric.verhoeven@gmail.com",
          phone: "06-12345678",
          relation_type: {
            label: "Mijn kind",
            code: "child",
          },
          partnerSelected: null,
          partner: false,
          partnerOptions: [],
          login: true,
        }*/
      ],
      fields: [
        { key: 'image', label: '' },
        { key: 'firstname', label: 'Voornaam' },
        { key: 'lastname', label: 'Achternaam' },
        { key: 'relation', label: 'Relatie' },
        { key: 'birthday', label: 'Geboortedatum', formatter: "formatDate" },
        { key: 'phone', label: 'Telefoonnummer' },
        { key: 'email', label: 'Email' },
        { key: 'login', label: 'Inlogaccount' },
        { key: 'edit', label: '', tdClass: 'text-right', },
      ],
      editedUser: null,
    }
  },
  mounted() {
    this.reloadUsers();
  },
  methods: {
    reloadUsers() {
      let _this = this;
      this.$http.get("/users").then(response => {
        _this.users = response.data.users;
      });
    },
    formatDate(value) {
      let options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      let date = new Date(value.replace(/-/g, '/').replace('T', ' '));

      return date.toLocaleDateString("nl-NL", options)
    },
    newUser() {
      this.$bvModal.show('editUser')
      this.editedUser = null
    },
    editUser(user, index) {
      this.$bvModal.show('editUser')
      this.editedUser = user
      this.editedUser.index = index
    },
    userEdited(user) {
      this.reloadUsers();
      this.$bvModal.hide('edit-relation-' + user.hashid);
    },
    addUser(payload) {
      this.users.push(payload);
      if (payload.relation_type === 'child') {
        // before we would add any new user to the db.children. However, only childrens hould be added.
        this.$auth.db.children.push(payload);
      }
    },
    deleteUser() {
      this.reloadUsers();
    },
  },
}
</script>

<style lang="scss">
// On smaller devices, the modal will be right up to the top of the screen. This solves that issue.
@media (max-width: 768px) {
  .relation-modal {
    margin-top: 100px !important;
    margin-bottom: 50px !important;
  }
}

// make sure that all items are aligned in center
.form-row {
  display: flex;
  align-items: center;
}
</style>

<style lang="scss" scoped>
.text-wrapping {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.blocked-relation {
  background-color: rgba(255, 0, 0, 0.1);
}
</style>
