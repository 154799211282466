<template>
  <div v-if="$auth.db.family && $auth.db.family.image" class="family-image-wrapper" >
    <img :src="$auth.db.family.image_base64" class="family-image"/>
  </div>
</template>

<script>

export default {
  name: "Family-Image",
  props: {
  },
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>

.family-image-wrapper {
  position: relative;
  width: 100%;
  height: 200px;
  margin-bottom: 30px;
  
  .family-image {
    width: 100%;
    height: 100%;
    background-position: center center;
    border-radius: 30px;
    object-fit: cover;
  }
}
</style>
